import { Injectable } from "@angular/core";
import { CacheService } from "./cache.service";
import { GeneralService, UserService } from "src/app/commons/services";

interface userInfo {
  type: string;
  user_info: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    street: string;
    city: string;
    door_code: string;
    instructions: string;
    additional_phone: string;
    country: string;
    zip_code: string;
  };
}

interface checkoutInfo {
  isDifferentBillingInfoAsShippingInfo: boolean;
  deliveryMethod: any;
  paymentMethod: any;
  addressId: number;
  promo: string;
  promocodes: string[];
  collico: {
    date: string;
    timeFrom: string;
    timeTo: string;
  };
  pickupLocationId: number;
}

@Injectable({
  providedIn: "root",
})
export class CentralStorageService {
  constructor(
    private cacheService: CacheService,
    private userService: UserService,
    private generalService: GeneralService
  ) {}

  get getStoredUserShippingInfo() {
    return this.cacheService.observeOn("user-shipping-info");
  }

  get getStoredCheckoutInfo() {
    return this.cacheService.observeOn("checkout-info");
  }

  getCentralUserInfoDefaultProperies() {
    const userInfo: userInfo = {
      type: "default",
      user_info: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        street: null,
        city: null,
        door_code: null,
        instructions: null,
        additional_phone: null,
        country: "Finland",
        zip_code: null,
      },
    };

    return userInfo;
  }

  async setCheckoutInfoDefault() {
    const checkoutInfo: checkoutInfo = {
      isDifferentBillingInfoAsShippingInfo: false,
      deliveryMethod: null,
      paymentMethod: null,
      addressId: null,
      promo: null,
      promocodes: [],
      collico: {
        date: null,
        timeFrom: null,
        timeTo: null,
      },
      pickupLocationId: null,
    };
    await this.cacheService.set("checkout-info", checkoutInfo);
  }

  async setCheckoutInfoByCheckoutPage(key, value) {
    const storedCheckoutInfo: checkoutInfo =
      this.cacheService.get("checkout-info");
    const updatedCheckoutInfo = { ...storedCheckoutInfo, [key]: value };
    this.cacheService.set("checkout-info", updatedCheckoutInfo);
  }

  async setMultipleCheckoutInfoByCheckoutPage(data) {
    const storedCheckoutInfo: checkoutInfo = await this.cacheService.get(
      "checkout-info"
    );
    const updatedCheckoutInfo = { ...storedCheckoutInfo, ...data };
    await this.cacheService.set("checkout-info", updatedCheckoutInfo);
  }

  async setCheckoutInfoCollicoDateByZipPopup(date: string) {
    const storedCheckoutInfo: checkoutInfo = await this.cacheService.get(
      "checkout-info"
    );
    const updatedCheckoutInfo = {
      ...storedCheckoutInfo,
      collico: { ...storedCheckoutInfo.collico, date },
    };
    await this.cacheService.set("checkout-info", updatedCheckoutInfo);
  }

  async setUserShippingInfoDefault() {
    const userInfo: userInfo = { ...this.getCentralUserInfoDefaultProperies() };
    await this.cacheService.set("user-shipping-info", userInfo);
  }

  async setUserShippingInfoByZipPopup(zipCode: string) {
    const storedUserInfo: userInfo = await this.cacheService.get(
      "user-shipping-info"
    );
    const userInfo: userInfo = {
      type: "zip_code_popup",
      user_info: {
        ...storedUserInfo.user_info,
        zip_code: zipCode,
      },
    };

    await this.cacheService.set("user-shipping-info", userInfo);
  }

  async setUserShippingInfoWhenLoggedIn(user) {
    const storedUserInfo: userInfo = await this.cacheService.get(
      "user-shipping-info"
    );
    const validStoredUserInfo =
      this.generalService.removeEmptyPropertiesFromObject(
        storedUserInfo.user_info
      );
    const mappedStoredUserInfo =
      this.transformUserInfoProperties(validStoredUserInfo);
    let isAddressFound: boolean = false;

    if (storedUserInfo) {
      const { zip_code: stored_zip_code } = storedUserInfo.user_info;
      const { firstname, lastname, email, phone } = user;
      let previousUserInfo = null;

      const shippingAddresses: any =
        await this.userService.getShippingAddresses();
      const defaultShippingAddresses = shippingAddresses.filter(
        (x) => x.is_default === 1
      );

      if (
        !this.generalService.isEmptyObject(mappedStoredUserInfo) &&
        defaultShippingAddresses.length > 0
      ) {
        const shippingAddressIndex =
          this.getIndexOfShippingAddressBasedOnMappedKeys(
            defaultShippingAddresses,
            mappedStoredUserInfo
          );

        if (shippingAddressIndex >= 0) {
          previousUserInfo = {
            user_info: defaultShippingAddresses[shippingAddressIndex],
            type: "same_zip_primary_info",
          };
          isAddressFound = true;
        }
      }
      if (
        !isAddressFound &&
        !this.generalService.isEmptyObject(mappedStoredUserInfo) &&
        shippingAddresses.length > 0
      ) {
        const shippingAddressIndex =
          this.getIndexOfShippingAddressBasedOnMappedKeys(
            shippingAddresses,
            mappedStoredUserInfo
          );

        if (shippingAddressIndex >= 0) {
          previousUserInfo = {
            user_info: shippingAddresses[shippingAddressIndex],
            type: "same_mapped_info",
          };
          isAddressFound = true;
        }
      }
      if (!isAddressFound && user) {
        let isMapUserInfo = false;
        if (["editing_shipping_info"].includes(storedUserInfo.type)) {
          const {
            firstname: storedFirstname,
            lastname: storedLastname,
            email: storedEmail,
            phone: storedPhone,
          } = storedUserInfo.user_info;
          if (storedFirstname && storedLastname && storedEmail && storedPhone) {
            isMapUserInfo = false;
          } else {
            isMapUserInfo = true;
          }
        } else {
          isMapUserInfo = true;
        }

        if (isMapUserInfo) {
          previousUserInfo = {
            user_info: {
              shipping_firstname: firstname,
              shipping_lastname: lastname,
              shipping_email: email,
              shipping_phone: phone,
              shipping_street: null,
              shipping_city: null,
              door_code: null,
              delivery_notes: null,
              additional_phone: null,
              shipping_country: "Finland",
              shipping_zip: stored_zip_code,
            },
            type: "logged_in",
          };
          isAddressFound = true;
        }
      }

      if (isAddressFound) {
        const {
          shipping_firstname: firstname,
          shipping_lastname: lastname,
          shipping_email: email,
          shipping_phone: phone,
          shipping_street: street,
          shipping_city: city,
          door_code,
          delivery_notes: instructions,
          additional_phone,
          shipping_country: country,
          shipping_zip: zip_code,
        } = previousUserInfo.user_info;

        if (
          [
            "default",
            "logged_in",
            "zip_code_popup",
            "editing_shipping_info",
          ].includes(storedUserInfo.type)
        ) {
          const userInfo: userInfo = {
            type: previousUserInfo.type,
            user_info: {
              firstname,
              lastname,
              email,
              phone,
              street,
              city,
              door_code,
              instructions,
              additional_phone,
              country,
              zip_code: zip_code,
            },
          };

          await this.cacheService.set("user-shipping-info", userInfo);
        }
      }
    }
  }

  async setUserShippingInfoBySelectingShippingAddress(shippingAddress) {
    const {
      shipping_firstname: firstname,
      shipping_lastname: lastname,
      shipping_email: email,
      shipping_phone: phone,
      shipping_street: street,
      shipping_city: city,
      door_code,
      delivery_notes: instructions,
      additional_phone,
      country,
      shipping_zip: zip_code,
    } = shippingAddress;

    const userInfo: userInfo = {
      type: "select_shipping_info",
      user_info: {
        firstname,
        lastname,
        email,
        phone,
        street,
        city,
        door_code,
        instructions,
        additional_phone,
        country: "Finland",
        zip_code,
      },
    };

    await this.cacheService.set("user-shipping-info", userInfo);

    return userInfo;
  }

  async setUserShippingInfoByEditingShippingAddress(shippingAddress) {
    const {
      firstname,
      lastname,
      email,
      phone,
      street,
      city,
      door_code,
      instructions,
      additional_phone,
      country,
      zip_code,
    } = shippingAddress;

    const userInfo: userInfo = {
      type: "editing_shipping_info",
      user_info: {
        firstname,
        lastname,
        email,
        phone,
        street,
        city,
        door_code,
        instructions,
        additional_phone,
        country: "Finland",
        zip_code,
      },
    };

    await this.cacheService.set("user-shipping-info", userInfo);

    return userInfo;
  }

  async setUserBillingInfoDefault() {
    const userBillingInfo: userInfo = {
      ...this.getCentralUserInfoDefaultProperies(),
    };
    await this.cacheService.set("user-billing-info", userBillingInfo);
  }

  async setUserBillingInfoByEditingBillingAddress(billingAddress) {
    const {
      firstname,
      lastname,
      email,
      phone,
      street,
      city,
      door_code,
      instructions,
      additional_phone,
      country,
      zip_code,
    } = billingAddress;

    const userInfo: userInfo = {
      type: "editing_billing_info",
      user_info: {
        firstname,
        lastname,
        email,
        phone,
        street,
        city,
        door_code,
        instructions,
        additional_phone,
        country,
        zip_code,
      },
    };

    await this.cacheService.set("user-billing-info", userInfo);
  }

  async resetAllCentralStoredKeys() {
    const userShippingInfo: userInfo = await this.cacheService.get(
      "user-shipping-info"
    );
    const { zip_code } = userShippingInfo.user_info;
    const userInfo: userInfo = { ...this.getCentralUserInfoDefaultProperies() };
    userInfo.user_info.zip_code = zip_code;

    await this.cacheService.set("user-shipping-info", userInfo);
    await this.cacheService.remove("user-billing-info");
    await this.cacheService.remove("checkout-info");
  }

  getKeysForPurchaseBtn() {
    const userShippingInfo: userInfo =
      this.cacheService.get("user-shipping-info");
    const checkoutInfo: checkoutInfo = this.cacheService.get("checkout-info");
    const zipCode = userShippingInfo?.user_info?.zip_code;
    const deliveryDate = checkoutInfo?.collico?.date || null;
    const deliveryMethod = checkoutInfo?.deliveryMethod;

    return {
      zip_code: zipCode,
      delivery_date: deliveryDate,
      delivery_method: deliveryMethod,
    };
  }

  setVendorCode(vendorCode: string): void {
    this.cacheService.set("vendor-tag", vendorCode);
  }

  getVendorCode(): string | null {
    return this.cacheService.get("vendor-tag") || null;
  }

  getFromKey(key: string) {
    return this.cacheService.get(key);
  }

  private transformUserInfoProperties(userInfoObj: any) {
    let userInfo = { ...userInfoObj };
    const transformations = [
      { normal_key: "firstname", shipping_key: "shipping_firstname" },
      { normal_key: "lastname", shipping_key: "shipping_lastname" },
      { normal_key: "email", shipping_key: "shipping_email" },
      { normal_key: "phone", shipping_key: "shipping_phone" },
      { normal_key: "street", shipping_key: "shipping_street" },
      { normal_key: "city", shipping_key: "shipping_city" },
      { normal_key: "country", shipping_key: "shipping_country" },
      { normal_key: "zip_code", shipping_key: "shipping_zip" },
    ];

    Object.entries(userInfo).forEach(([key, val], i) => {
      const transformation = transformations.find((a) => a.normal_key === key);
      if (transformation) {
        userInfo[transformation.shipping_key] = val;
      }
      delete userInfo[key];
    });

    return userInfo;
  }

  private getIndexOfShippingAddressBasedOnMappedKeys(
    shippingAddresses = [],
    mappedStoredUserInfo = {}
  ) {
    const mappedStoredUserInfoKeys = Object.keys(mappedStoredUserInfo);
    const shippingAddressesWithOnlyMappedKeys = shippingAddresses.map(
      (address) => {
        const obj = {};
        mappedStoredUserInfoKeys.forEach((col) => (obj[col] = address[col]));
        return obj;
      }
    );

    const shippingAddressIndex = shippingAddressesWithOnlyMappedKeys.findIndex(
      (s) => {
        if (JSON.stringify(s) === JSON.stringify(mappedStoredUserInfo)) {
          return s;
        }
      }
    );

    return shippingAddressIndex;
  }
}
