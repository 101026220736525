import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import {
  ApiService,
  CacheService,
  CentralStorageService,
} from "src/app/core/services";
import { IQuery } from "src/app/core/ITypes";
import { ICartPayload, ICartItems, ICartItemPayload } from "../ITypes";
import { UserService } from "./user.service";
import { map } from "rxjs/operators";
import { IPickupLocation } from "src/app/layout/cart/ITypes";

@Injectable({
  providedIn: "root",
})
export class ShopService {
  // default
  private cartLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public cartData: BehaviorSubject<ICartItems> = new BehaviorSubject(null);
  browserLang: string = "default";
  nextPage: string;

  constructor(
    private api: ApiService,
    private cacheService: CacheService,
    private userService: UserService,
    private centralStorageService: CentralStorageService
  ) {}

  observeCartLoading() {
    return this.cartLoading.asObservable();
  }

  async createCart(data: ICartPayload) {
    try {
      const { status_code, result } = await this.api
        .post(data, "/carts")
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  async getCarts(query: IQuery) {
    try {
      const { status_code, result } = await this.api
        .get(`/carts`, query)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getLatestCart(query: IQuery) {
    try {
      const { status_code, result } = await this.api
        .get(`/carts`, query)
        .toPromise();

      if (status_code === 200 && result) {
        let r: any = result;
        let sorted = r.sort(function compare(a, b) {
          let dateA: any = new Date(a.created_at);
          let dateB: any = new Date(b.created_at);
          return dateB - dateA;
        });

        return sorted[0];
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getCart(cartId: number, query: IQuery) {
    try {
      this.cartLoading.next(true);

      const { status_code, result } = await this.api
        .getById(`/carts`, String(cartId), query)
        .toPromise();

      this.cartLoading.next(false);
      if (status_code === 200 && result) {
        const { items, ...data } = result;

        // set storage here.. update cart in storage / cache service
        let cacheCart = {
          id: result.id,
          products: [],
        };
        this.cacheService.set("cart", cacheCart);

        if (items.length) {
          items.forEach((item) => {
            cacheCart.products.push({
              id: item.summary.default.C000,
              price: item.summary.default.F004,
              quantity: item.quantity,
            });
          });
        }
        this.cacheService.set("cart", cacheCart);
        // set storage here.. update cart in storage / cache service ends ....

        // fire validate products .. from cacheservice
        // remove products local storage
        let validatedProducts = this.validateProducts(items, result);

        // can map items here directly too without validation
        const products = validatedProducts.map(
          ({ quantity, summary }: ICartItems) => ({
            quantity,
            price: parseFloat(summary["default"]["F004"]),
            ...summary[this.browserLang],
          })
        );

        this.cartData.next({
          products,
          ...data,
        });

        let cart = { products, ...data };
        return cart;
      } else {
        // un handled errors or conditions delete cart.
        // this.cacheService.remove("cart");
        // this.deleteCart(cartId, {
        //   user_uuid: this.userService.getUserUUID,
        // });
      }
    } catch (error) {
      this.cartLoading.next(false);

      this.cacheService.remove("cart");
      this.deleteCart(cartId, {
        user_uuid: this.userService.getUserUUID,
      });
      throw error;
    }
    // }
  }

  async getCartFromServer(cartId: number, query: IQuery) {
    try {
      let data = {
        id: 0,
        products: [],
      };

      this.cartLoading.next(true);
      const { status_code, result } = await this.api
        .getById(`/carts`, String(cartId), query)
        .toPromise();
      this.cartLoading.next(false);

      if (status_code === 200 && result) {
        const products = result.items.map((item) => ({
          ...item.summary[this.browserLang],
          id: item.summary.default.C000,
          price: parseFloat(item.summary.default.F004),
          quantity: Number(item.quantity),
        }));

        data = {
          id: result.id,
          products,
        };
      }

      return data;
    } catch (error) {
      this.cartLoading.next(false);
    }
  }

  validateProducts(items?: any[], result?: any) {
    let localCart = this.cacheService.get("cart");
    if (localCart) {
      let filteredProducts = [];
      let filteredLocalCart = [];

      items.forEach((item) => {
        localCart.products.forEach((product) => {
          if (item.summary.default.C000 === product.id) {
            filteredProducts.push(item);
            filteredLocalCart.push(product);
          }
        });
      });

      localCart.products = filteredLocalCart;
      this.cacheService.set("cart", localCart);

      return filteredProducts;
    } else if (result) {
      let cart = {
        id: result.id,
        products: [],
      };

      result.items.forEach((item) => {
        if (item && item.summary) {
          cart.products.push({
            id: item.summary.default.C000,
            price: item.summary.default.F004,
            quantity: item.quantity,
          });
        }
      });

      this.cacheService.set("cart", cart);
      // this.cartService.setProductsTotal(cart.products);
      return result.items;
    }
    return [];
  }

  async addCartItem(cartId: number, data: ICartItemPayload) {
    try {
      const { status_code, result } = await this.api
        .post(data, `/carts/${cartId}/items`)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  //  Update Cart starts  ............................................
  async updateCart(cartId: number, data: any, query: IQuery) {
    try {
      const { status_code, result } = await this.api
        .put(data, `/carts/${cartId}`, query)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }
  //  Update Cart starts  ............................................

  async updateCartItem(
    cartId: number,
    productId: string,
    data: ICartItemPayload
  ) {
    const { result } = await this.api
      .put(data, `/carts/${cartId}/items/${productId}`)
      .toPromise();

    return result;
  }

  async deleteCartItem(cartId: number, productId: string, query: IQuery) {
    try {
      const { status_code, result } = await this.api
        .delete(`/carts/${cartId}/items/${productId}`, query)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  async deleteCart(cartId: number, query: IQuery) {
    try {
      const { status_code, result } = await this.api
        .delete(`/carts/${cartId}`, query)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  async getPaymentMethods(query?: IQuery) {
    try {
      const { status_code, result } = await this.api
        .get(`/payment_methods`, query)
        .toPromise();

      if (status_code === 200 && result) {
        return result.data;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getNotAvailableZipCodes(query?: IQuery) {
    try {
      const { status_code, result } = await this.api
        .get("/restricted_postal_codes")
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getNonDeliveryDates(query?: IQuery) {
    try {
      const { status_code, result } = await this.api
        .get("/no_delivery_dates")
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getDeliveryMethods(query?: IQuery) {
    try {
      const { status_code, result } = await this.api
        .get("/delivery_methods")
        .toPromise();

      if (status_code === 200 && result) {
        const filteredResult = result.map(({ zones, ...rest }) => rest);
        return filteredResult;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async checkProductValidity(cartId: number, query: IQuery) {
    try {
      const { zip_code, delivery_date, delivery_method } =
        await this.centralStorageService.getKeysForPurchaseBtn();
      const iQuery = {
        ...query,
        zip_code,
        delivery_date,
        delivery_method: delivery_method?.code || null,
        user_uuid: this.userService.getUserUUID,
      };

      const { status_code, result } = await this.api
        .get(`/carts/${cartId}/check-validity`, iQuery)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return [];
    } catch (error) {
      console.error("Check Productivity URL error : ", error);
      throw error;
    }
  }

  async getDeliveryTimes(query?: IQuery) {
    try {
      const { zip_code, delivery_method } =
        this.centralStorageService.getKeysForPurchaseBtn();
      const cart = this.cacheService.get("cart");

      const iQuery = {
        ...query,
        zip_code,
        cart_id: cart?.id || null,
        delivery_code: delivery_method?.code || null,
      };

      const { status_code, result } = await this.api
        .get("/delivery_dates/charges", iQuery)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async loadPickupLocations(
    query?: IQuery
  ): Promise<Observable<IPickupLocation>> {
    try {
      const { zip_code } =
        await this.centralStorageService.getKeysForPurchaseBtn();
      const iQuery = {
        ...query,
        zip_code,
      };

      const { status_code, result } = await this.api
        .get("/pickup_locations", iQuery)
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return result;
    } catch (error) {
      throw error;
    }
  }
}
