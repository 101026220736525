import { Component, HostListener, OnInit, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DisplayGroupService, ShopService, UserService } from "../services";
import { GeneralService } from "../services/general.service";
import {
  IPreference,
  IUser,
  ISearchFilter,
  IDisplayGroups,
  IConfig,
} from "src/app/core/ITypes";
import {
  CartService,
  AuthService,
  SiteConfigService,
  CacheService,
  ApiService,
  CentralStorageService,
} from "src/app/core/services";
import { TranslateService } from "@ngx-translate/core";
import { CollicoService } from "../services/collico.service";

@Component({
  selector: "sv-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  user: IUser;
  cart = { id: 0, products: [], total: 0 };
  title: string = "";
  location: string = null;
  date: string = null;
  time: string = null;
  searchWord: string = null;
  searchFiltersCount: number = 0;
  cartTotal: number = 0;
  dropDownOptions: boolean = false;
  displayMore: boolean = false;
  searchFocused: boolean = false;
  loggedIn: boolean = false;
  zipBox: boolean = false;
  zipInfoBox: boolean = false;
  isSearchFiltersVisible: boolean = false;
  preferences: any = null;
  availableSearchTags: IDisplayGroups[] = [];
  moreSearchTags: IDisplayGroups[] = [];
  deliveryDayDate: number = 0;
  deliveryMonth: string = "";
  zipCode: string = null;
  zipCodePref: string = "";
  zipCodeLength: number = 0;
  isValidZipCode: boolean = false;
  subOptions: string[] = [];
  availableSearchFilters: string[] = [];
  isPreviousSearchVisible: boolean = false;
  searchTags: any[] = [];
  searchFilters: ISearchFilter[] = [
    {
      label: { en: "Promotions", fi: "Tarjoukset" },
      checked: false,
      key: "F026",
    },
    {
      label: { en: "New", fi: "Uutuudet" },
      checked: false,
      key: "F027",
    },
    {
      label: { en: "Organic", fi: "Luomumerkki" },
      checked: false,
      key: "F025",
    },
    { label: { en: "Local", fi: "Kotimainen" }, checked: false, key: "F028" },
    { label: { en: "Vegan", fi: "Vegaani" }, checked: false, key: "F022" },
    {
      label: { en: "Gluten free", fi: "Gluteenittomat" },
      checked: false,
      key: "F023",
    },
    {
      label: { en: "Lactose free", fi: "Laktoosittomat" },
      checked: false,
      key: "F024",
    },
    { label: { en: "Frozen", fi: "Pakasteet" }, checked: false, key: "F021" },
  ];
  browserLang: string = "fi";
  deliveryMethods: any = [];
  collicoZones: any = [];
  previousSearches: string[] = [];
  mode: string = "";
  selectedZone: any = null;
  nearestAvailableTime: any = null;
  availableSearchTags2: any[] = [];
  moreSearchTags2: any[] = [];
  hasSearchTags: boolean = false;
  filtersParams: string = "";
  zipCodeText: string = "";
  zipCodeErrorMsg: string = "";
  showNotificationBar: boolean = false;
  displayGroups: IDisplayGroups[] = [];
  localization: IConfig["localization"] = null;

  dropDownListener: () => void;
  zipBoxListener: () => void;
  searchBoxListener: () => void;

  constructor(
    private authService: AuthService,
    public generalService: GeneralService,
    private router: Router,
    private cartService: CartService,
    private renderer: Renderer2,
    private displayGroupService: DisplayGroupService,
    public siteConfigService: SiteConfigService,
    private storageService: CacheService,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    public collicoService: CollicoService,
    private centralStorageService: CentralStorageService
  ) {
    this.cart = this.cartService.cartData || this.cart;
    this.collicoService.getNonDeliveryDates();

    const { name, searchBar, hasSearchTags, localization } =
      this.siteConfigService.siteConfig;
    this.title = name;
    this.isSearchFiltersVisible = searchBar.isSearchFiltersVisible;
    this.hasSearchTags = hasSearchTags;
    this.localization = localization;

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (params) => {
        this.displayMore = false;
        this.moreSearchTags = [];
        this.searchTags = [];
        const rawSearchTags: any = this.displayGroupService.displayGroups;
        if (!params.group) {
          this.moreSearchTags = [];
          if (rawSearchTags.length > 12) {
            this.availableSearchTags = rawSearchTags.slice(0, 6);
            this.moreSearchTags = rawSearchTags.slice(6, rawSearchTags.length);
          } else {
            this.availableSearchTags = rawSearchTags;
          }
        } else {
          let { group } = this.route.snapshot.queryParams;

          this.availableSearchTags.forEach((s: any) => {
            if (s.code === params.subGroup && hasSearchTags === false) {
              this.searchTags = [s];
            }

            if (s.code === group) {
              this.searchTags = [s];
            }
          });

          for (let index = 0; index < rawSearchTags.length; index++) {
            const r = rawSearchTags[index];

            let groudCode = params.subGroup || params.group;

            if (r.code === groudCode) {
              this.searchTags = [r];

              if (r.display_groups.length) {
                this.availableSearchTags = r.display_groups;
              }
            } else {
              if (r.display_groups) {
                for (let j = 0; j < r.display_groups.length; j++) {
                  const d = r.display_groups[j];
                  if (d.code === groudCode) {
                    this.searchTags = [d];
                    this.availableSearchTags = r.display_groups;
                  }
                }
              }
            }
          }

          if (this.availableSearchTags.length > 12) {
            this.availableSearchTags = this.availableSearchTags.slice(0, 6);
            this.moreSearchTags = this.availableSearchTags.slice(
              6,
              this.availableSearchTags.length
            );
          } else {
            this.availableSearchTags = this.availableSearchTags;
          }

          if (hasSearchTags === true) {
            let searchTags: any = await this.displayGroupService.getSearchTags(
              params.group
            );

            this.availableSearchTags2 = searchTags;
            this.moreSearchTags2 = [];
          }

          if (!params.q) {
            this.searchWord = "";
          }
        }

        if (params.q) {
          this.searchWord = params.q;
          this.filtersParams = "";
        }

        if (params.filters) {
          this.filtersParams = params.filters;

          let filters = JSON.parse(params.filters);
          this.searchFiltersCount = 0;

          for (let index = 0; index < this.searchFilters.length; index++) {
            this.searchFilters[index].checked = false;
          }

          for (let index = 0; index < filters.length; index++) {
            const filter = filters[index];

            let foundIndex = this.searchFilters.findIndex((f) => {
              if (f.key === filter) {
                this.searchFiltersCount += 1;
                return f;
              }
            });

            this.searchFilters[foundIndex].checked = true;
          }
        } else {
          // this.filtersParams = "";
        }
      });
  }

  async ngOnInit() {
    let s = this.storageService.get("search");
    this.previousSearches = s || [];
    this.browserLang = this.translateService.getBrowserLang();

    this.translateService.get("hello.world").subscribe((translated: string) => {
      this.zipCodeErrorMsg = this.translateService.instant(
        `${this.localization.ZIP_POPUP_ENTER_TEXT}.value`
      );
    });

    this.authService.isLoggedIn
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
      });

    this.cartService.cartDataObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cartData) => {
        if (cartData) {
          const { products, total } = cartData;
          this.cart.products = products;
          this.cartTotal = total;
        }
      });

    // observe if display group changes
    this.displayGroupService.displayGroupsObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (displayGroupData) => {
        if (displayGroupData) {
          this.displayGroups = displayGroupData;

          // display groups are searchTags here...
          let rawSearchTags: any = displayGroupData;

          if (rawSearchTags.length > 12) {
            this.availableSearchTags = rawSearchTags.slice(0, 6);
            this.moreSearchTags = rawSearchTags.slice(6, rawSearchTags.length);
          } else {
            this.availableSearchTags = rawSearchTags;
          }

          // searchTags are defined seperately here...
          let searchTags: any = await this.displayGroupService.getSearchTags(
            "frontPage"
          );

          if (searchTags.length > 12) {
            this.availableSearchTags2 = searchTags.slice(0, 6);
            this.moreSearchTags2 = searchTags.slice(6, searchTags.length);
          } else {
            this.availableSearchTags2 = searchTags;
          }
        }
      });

    // Subscribe to the zip code change events
    this.collicoService.collicoZipChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((zipCode) => {
        this.zipCode = zipCode; // Store the latest zip code
        this.handleZipCodeModal();
      });
    // Subscribe to the router events
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleZipCodeModal();
      }
    });

    this.generalService.showNotificationBar$.subscribe((val) => {
      this.showNotificationBar = val;
    });
  }

  handleToggleProductTree() {
    this.generalService.setProductTreeState =
      !this.generalService.getProductTreeState;
  }

  handleToggleCartPanel() {
    let isCartRoute = this.router.url.search("/cart");

    if (isCartRoute === -1) {
      this.generalService.setCartPanelState =
        !this.generalService.getCartPanelState;
      this.generalService.zipPopUpVisible = false;
    } else {
      let document = this.generalService.getDocument;
      let productSection = document.getElementById("cart-page");
      if (productSection) {
        productSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  addToSearchTags(choice) {
    this.searchBarFocusToggle();
    this.searchWord = "";

    if (this.hasSearchTags === true) {
      let tagKey = choice.key;
      this.searchTags = [choice];

      this.router.navigate(["/p/search"], {
        queryParams: {
          // group: choice.code,
          // q: this.searchWord
          mode: this.generalService.debugMode ? "debug" : null,
          tag: tagKey,
        },
      });
    }

    if (this.hasSearchTags === false) {
      this.searchTags = [choice];
      let parentGroup = choice.code;
      let subGroup = null;
      if (choice.parent != 0) {
        const findParentDisplayGroup = this.displayGroups.find(
          (x) => x.id == choice.parent
        );
        parentGroup = findParentDisplayGroup
          ? findParentDisplayGroup.code
          : null;
        subGroup = choice.code;
      }

      this.router.navigate(["/p/search"], {
        queryParams: {
          group: parentGroup,
          mode: this.generalService.debugMode ? "debug" : null,
          q: this.searchWord,
          filters: this.filtersParams,
          ...(subGroup && { subGroup }),
          // tag: choice.code
        },
      });
    }

    //  un comment this condition when multiple are appending to searchBar
    // if (this.SearchTags.indexOf(choice) === -1) {
    //   this.SearchTags.push(choice);
    // }
  }

  toggleMore() {
    this.displayMore = !this.displayMore;
  }

  removeFromSearchTags(choice) {
    this.searchTags = this.searchTags.filter((word) => word !== choice);

    this.router.navigate(["/p/search"], {
      queryParams: { q: this.searchWord, filters: this.filtersParams },
    });
  }

  searchBarFocusToggle() {
    // this.searchFocused = !this.searchFocused;
    if (this.searchFocused) {
      this.searchFocused = false;
      this.searchBoxListener();
      // ^ this is angular's Render2 unlisten function, same as window.removeEventListener
    } else {
      this.searchFocused = true;
      this.searchBoxListener = this.renderer.listen(
        "window",
        "click",
        (e: MouseEvent) => {
          let div = e.target as HTMLElement;
          if (div.className.search("search-ignore2") === -1) {
            this.searchBarFocusToggle();
          }
        }
      );
    }
  }

  search() {
    this.searchBarFocusToggle();
    let searchObj = {
      searchTags: this.searchTags[0] || "",
      searchFilters: this.searchFilters.filter((a) => a.checked),
      searchWord: this.searchWord || "",
    };

    const searchParam = this.route.snapshot.queryParams["q"];
    // if (!this.searchWord && !searchParam) {
    //   return;
    // }
    let queryParams = {};

    let filtersArray = [];

    if (searchObj.searchFilters.length) {
      searchObj.searchFilters.forEach((s, i) => {
        //   queryParams = { ...queryParams, [s.key]: true };

        filtersArray.push(s.key);
      });

      queryParams = {
        ...queryParams,
        filters: JSON.stringify(filtersArray),
      };
    }

    if (this.searchTags.length) {
      queryParams = { ...queryParams, group: this.searchTags[0].code };
    }
    if (this.searchWord) {
      queryParams = { ...queryParams, q: this.searchWord };

      this.previousSearches.length
        ? this.previousSearches.unshift(this.searchWord)
        : (this.previousSearches = [this.searchWord]);

      this.storageService.set("search", this.previousSearches);

      if (this.previousSearches.length >= 6) {
        this.previousSearches = this.previousSearches.splice(0, 6);
      }
    } else {
    }

    this.router.navigate(["/p/search"], { queryParams });
  }

  toggleDropDown() {
    if (this.dropDownOptions) {
      this.dropDownOptions = false;
      this.dropDownListener();
      // ^ this is angular's Render2 unlisten function, same as window.removeEventListener
    } else {
      this.dropDownOptions = true;
      this.dropDownListener = this.renderer.listen(
        "window",
        "click",
        (e: MouseEvent) => {
          let div = e.target as HTMLElement;
          if (div.className.search("search-ignore") === -1) {
            this.toggleDropDown();
          }
        }
      );
    }
  }

  addToSearchFilters(choice) {
    this.searchFiltersCount = 0;
    this.filtersParams = "";

    this.searchFilters.forEach((a) => {
      if (a.key === choice.key) {
        a.checked = !choice.checked;
      }

      if (a.checked === true) {
        this.searchFiltersCount = this.searchFiltersCount + 1;
      }
    });

    this.search();
  }

  searchBarOnChange(event: KeyboardEvent) {
    let div = event.target as HTMLInputElement;

    if (event.key == "Escape") {
      div.blur();
      this.searchBarFocusToggle();
    }
    if (event.key == "Enter") {
      div.blur();
      this.search();
    }
  }

  closeZipModal() {
    this.zipBox = false;
    this.zipInfoBox = false;
    this.generalService.zipPopUpVisible = false;
  }

  async toggleZip() {
    let isCartRoute = this.router.url.search("/cart");
    let isDeliveryRoute = this.router.url.search("/dashboard/delivery");

    if (this.collicoService.collicoZip) {
      this.closeZipModal();

      if (isCartRoute === -1) {
        this.router.navigate(["/cart"]);
      } else {
        let document = this.generalService.getDocument;
        let productSection = document.getElementById("address-block");
        if (productSection) {
          // productSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else if (isCartRoute !== -1 || isDeliveryRoute !== -1) {
      this.closeZipModal();
    } else {
      if (this.zipBox && !!this.zipBoxListener) {
        this.zipBox = false;
        this.generalService.zipPopUpVisible = false;
        this.zipBoxListener();
        // ^ this is angular's Render2 unlisten function, same as window.removeEventListener
      } else {
        this.zipBox = true;
        this.generalService.zipPopUpVisible = true;

        this.zipBoxListener = this.renderer.listen(
          "window",
          "click",
          (e: MouseEvent) => {
            let div = e.target as HTMLElement;
            if (div.className.search("zip-ignore") === -1) {
              this.toggleZip();
            }
          }
        );
      }
    }
  }

  async showMoreZipInfo(state: "open" | "close") {
    if (state === "close") {
      this.zipInfoBox = false;
    } else if (state === "open") {
      this.zipCodeLength = this.zipCode.length;
      this.isValidZipCode = true;
      let zipCode = this.zipCode;

      this.collicoService
        .getDeliveryAvailability({
          zip_code: zipCode,
        })
        .subscribe(async (deliveryAvailability) => {
          const { valid_zipcode, delivery_options } = deliveryAvailability;

          // Overwrite zipcode if delivery available only in certain areas
          if (valid_zipcode) {
            zipCode = valid_zipcode;
          }

          if (delivery_options) {
            this.collicoService.deliveryOptions = delivery_options;
            this.collicoService.setCollicoZip(zipCode);
            await this.collicoService.processDeliveryDateAndTime(zipCode);
            this.closeZipModal();
          } else {
            this.zipInfoBox = false;
            this.zipCode = null;
            this.zipCodeErrorMsg =
              this.translateService.instant("ZIP.Z004.value");
          }
        });
    }
  }

  searchAgain(item) {
    this.searchWord = item;
    let queryParams = {};

    if (this.searchTags.length) {
      queryParams = { ...queryParams, group: this.searchTags[0].code };
    }
    if (this.searchWord) {
      queryParams = { ...queryParams, q: this.searchWord };
    }

    this.searchBarFocusToggle();
    this.router.navigate(["/p/search"], { queryParams });
  }

  async changeZip(event?) {
    let zip = "";
    if (event) {
      zip = event.target.value + "";
      zip = zip.replace(/\s/g, "");
      zip = zip.replace(/\D+/g, "");
      this.zipCode = zip;
    }
    this.zipCodeLength = this.zipCode.length;

    if (this.zipCode && this.zipCodeLength !== 5) {
      this.zipInfoBox = false;
    }

    this.zipCodeErrorMsg = this.translateService.instant("ZIP.Z003.value");
  }

  private handleZipCodeModal() {
    const isCartRoute = this.router.url.includes("/cart");
    const isDeliveryRoute = this.router.url.includes("/dashboard/delivery");

    if (this.zipCode) {
      // If a zip code exists, close the modal
      this.closeZipModal();
    } else {
      // If no zip code exists, open the modal only if not on "/cart" or "/dashboard/delivery"
      if (!isCartRoute && !isDeliveryRoute) {
        this.generalService.zipPopUpVisible = true; // Open the popup
      } else {
        this.closeZipModal(); // Close the popup if on restricted routes
      }
    }
  }
}
