import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IQuery } from "src/app/core/ITypes";
import {
  ApiService,
  CacheService,
  CentralStorageService,
} from "src/app/core/services";
import { GeneralService } from "./general.service";
import { ShopService } from "./shop.service";
import { UserService } from "./user.service";
import { map } from "rxjs/operators";
import {
  IDeliveryAvailability,
  IDeliveryAvailabilityAndDates,
  IDeliveryDaySchedule,
  IDeliveryOption,
} from "src/app/layout/cart/ITypes";

@Injectable({
  providedIn: "root",
})
export class CollicoService {
  collicoAvailable: boolean = false;
  deliveryAvailable: boolean = true;
  hasLimitedAvailProducts: boolean = false;
  limitedAvailabilityProductsCount: number = 0;
  collicoZip: string = null;
  tempCollicoZip: string = null;
  products: any[] = null;
  selectedCollicoZone: any = null;
  selectedDeliverMehtod: any = null;
  nonDeliverableDates: any[] = [];
  nearestCollicoDeliveryDateAndTime: any = null;
  nearestAvailableTime: any = null;
  preSelectedDeliveryDate: Date = null;
  deliveryOptions: IDeliveryOption = null;
  deliveryDaysSchedule: IDeliveryDaySchedule[] = [];

  private unsubscribe$ = new Subject<void>();
  private collicoZipChanged = new BehaviorSubject<string>(null);
  private tempCollicoZipChanged = new BehaviorSubject<string>(null);
  private collicoDeliveryDateAndTimeChanged =
    new BehaviorSubject<IDeliveryDaySchedule>(null);

  // Expose an observable that others can subscribe to
  collicoZipChanged$ = this.collicoZipChanged.asObservable();
  tempCollicoZipChanged$ = this.tempCollicoZipChanged.asObservable();
  collicoDeliveryDateAndTimeChanged$ =
    this.collicoDeliveryDateAndTimeChanged.asObservable();

  constructor(
    private shopService: ShopService,
    private apiService: ApiService,
    private cacheService: CacheService,
    private generalService: GeneralService,
    private api: ApiService,
    private centralStorageService: CentralStorageService,
    private userService: UserService
  ) {
    this.shopService.cartData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tempCart) => {
        if (tempCart) {
          this.products = tempCart.products;
        }
      });
  }

  setCollicoZip(zip: string) {
    this.collicoZip = zip;
    this.collicoZipChanged.next(zip);

    // Store to Local Storage
    this.centralStorageService.setUserShippingInfoByZipPopup(zip);
  }

  setTempCollicoZip(zip: string) {
    this.tempCollicoZip = zip;
    this.tempCollicoZipChanged.next(zip);
  }

  setCollicoDeliveryDateAndTime(deliveryDateAndTime: IDeliveryDaySchedule) {
    let { date, default_start, default_end } = deliveryDateAndTime;

    this.nearestCollicoDeliveryDateAndTime = deliveryDateAndTime;
    this.collicoDeliveryDateAndTimeChanged.next(deliveryDateAndTime);
    const deliveryDateAndTimeFormatted = {
      date,
      timeFrom: default_start,
      timeTo: default_end,
    };

    // Store to Local Storage
    this.centralStorageService.setCheckoutInfoByCheckoutPage(
      "collico",
      deliveryDateAndTimeFormatted
    );
  }

  checkLimitedAvailability(products?: any) {
    this.products = products;

    if (this.products && this.products.length) {
      let limitedAvailProds = this.products.filter(
        (p) => p.F020 && p.F020 === true
      );

      if (limitedAvailProds && limitedAvailProds.length) {
        this.hasLimitedAvailProducts = true;
        this.limitedAvailabilityProductsCount = limitedAvailProds.length;

        return true;
      } else {
        this.hasLimitedAvailProducts = false;
        this.limitedAvailabilityProductsCount = 0;
      }
    } else {
      this.hasLimitedAvailProducts = false;
      this.limitedAvailabilityProductsCount = 0;
    }
    return false;
  }

  checkNonDeliverableDates(incomingDates) {
    let temp = incomingDates;

    if (!this.nonDeliverableDates.length) {
      this.getNonDeliveryDates();
    }

    if (incomingDates.length && this.nonDeliverableDates.length) {
      temp = [];
      for (let index = 0; index < incomingDates.length; index++) {
        const d = incomingDates[index];

        const found = this.nonDeliverableDates.find(
          (element) => element === d.date
        );

        if (found === undefined) {
          temp.push(d);
        }
      }
    }

    return temp;
  }

  async getNonDeliveryDates() {
    try {
      const { status_code, result } = await this.apiService
        .get("/no_delivery_dates")
        .toPromise();

      if (status_code && status_code === 200 && result) {
        let arr = [];
        let res: any = result;

        if (res && res.length) {
          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            arr.push(element.no_delivery_date);
          }

          if (arr.length) {
            this.nonDeliverableDates = arr;
            return arr;
          }
          return [];
        }

        // return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  getDeliveryDates(query?: IQuery): Observable<IDeliveryAvailabilityAndDates> {
    const cart = this.cacheService.get("cart");
    const { delivery_method } =
      this.centralStorageService.getKeysForPurchaseBtn();

    const iQuery = {
      ...query,
      cart_id: cart?.id || null,
      user_uuid: this.userService.getUserUUID,
      delivery_code: delivery_method?.code || null,
    };

    return this.api
      .get("/delivery_dates", iQuery)
      .pipe(map((response) => response.result));
  }

  getDeliveryAvailability(query?: IQuery): Observable<IDeliveryAvailability> {
    return this.api
      .get("/delivery_availability", query)
      .pipe(map((response) => response.result));
  }

  getZipCodeFromStorage() {
    const storedUserShippingInfo = this.cacheService.get("user-shipping-info");
    const { user_info } = storedUserShippingInfo;
    if (user_info && user_info.zip_code) {
      return user_info.zip_code;
    }
    return null;
  }

  async getCollicoDeliveryDate(
    deliveryDates: IDeliveryDaySchedule[],
    deliveryDate: string
  ): Promise<IDeliveryDaySchedule> {
    const storedUserShippingInfo = this.cacheService.get("user-shipping-info");
    const { user_info } = storedUserShippingInfo;
    const zipCode = this.collicoZip
      ? this.collicoZip
      : user_info && user_info.zip_code
      ? user_info.zip_code
      : null;

    let deliveryDatesI =
      deliveryDates.length === 0
        ? (await this.fetchDeliveryDates(zipCode)).delivery_dates
        : [...deliveryDates];

    const collicoDeliveryDateInfo =
      deliveryDatesI.find((x) => x.date === deliveryDate) ||
      deliveryDatesI.find(Boolean);

    return collicoDeliveryDateInfo;
  }

  private fetchDeliveryDates(zipCode: string): Promise<any> {
    return new Promise((resolve) => {
      this.getDeliveryDates({ zip_code: zipCode }).subscribe(
        (deliveryDates) => {
          resolve(deliveryDates);
        },
        (error) => {
          console.error("Error fetching delivery dates:", error);
          resolve({ delivery_dates: [] }); // Resolve with an empty array in case of an error
        }
      );
    });
  }

  async updateDeliveryDateAndTime() {
    const storedUserShippingInfo = this.cacheService.get("user-shipping-info");
    if (storedUserShippingInfo && storedUserShippingInfo.user_info.zip_code) {
      const { user_info } = storedUserShippingInfo;
      let zipCodeFromStorage = user_info.zip_code;
      this.getDeliveryAvailability({
        zip_code: zipCodeFromStorage,
      }).subscribe(async (deliveryAvailability) => {
        const { valid_zipcode, delivery_options } = deliveryAvailability;

        if (valid_zipcode) {
          zipCodeFromStorage = valid_zipcode;
        }

        if (delivery_options) {
          this.deliveryOptions = delivery_options;
          this.setCollicoZip(zipCodeFromStorage);
          await this.processDeliveryDateAndTime(zipCodeFromStorage);
        }
      });
    }
  }

  async processDeliveryDateAndTime(zipCode: string): Promise<void> {
    this.getDeliveryDates({
      zip_code: zipCode,
    }).subscribe((deliveryDates) => {
      const { delivery_dates } = deliveryDates;
      this.deliveryDaysSchedule = delivery_dates;
      const preSelectedDeliveryDaySchedule =
        this.handlePreSelectedDeliveryDaySchedule(
          this.preSelectedDeliveryDate,
          delivery_dates
        );

      // Set the Collico delivery date and time
      if (preSelectedDeliveryDaySchedule) {
        this.setCollicoDeliveryDateAndTime(preSelectedDeliveryDaySchedule);
      } else {
        this.handleNearestDeliveryDaySchedule(delivery_dates);
      }
    });
  }

  handleNearestDeliveryDaySchedule(
    deliveryDaysSchedule: IDeliveryDaySchedule[]
  ): void {
    const storedCheckoutInfo = this.cacheService.get("checkout-info");
    const { collico } = storedCheckoutInfo;

    // Find the nearest delivery date and time, accounting for potential null values
    let deliveryDaySchedule = deliveryDaysSchedule.find(Boolean) || null;

    if (deliveryDaySchedule) {
      const { date: storedDeliveryDate, timeFrom, timeTo } = collico;
      const { date: nearestDeliveryDate } = deliveryDaySchedule;

      // Parse dates for comparison
      const storedDeliveryDateParsed = Date.parse(storedDeliveryDate);
      const nearestDeliveryDateParsed = Date.parse(nearestDeliveryDate);

      if (
        storedCheckoutInfo &&
        storedDeliveryDateParsed >= nearestDeliveryDateParsed
      ) {
        // If the stored Delivery date is later or equal to the nearest delivery date,
        // re-verify the stored Delivery date is still valid
        const matchingSchedule = deliveryDaysSchedule.find(
          (x) => x.date === storedDeliveryDate
        );

        if (matchingSchedule) {
          // Use case: Changing from postal deliveries to refrigerator delivery
          if (matchingSchedule.default_start === "00:00") {
            deliveryDaySchedule = matchingSchedule;
          } else if (timeFrom !== "00:00" && timeTo !== "00:00") {
            deliveryDaySchedule = {
              ...matchingSchedule,
              default_start: timeFrom,
              default_end: timeTo,
            };
          } else {
            deliveryDaySchedule = matchingSchedule;
          }
        }
      }

      // Set the Collico delivery date and time
      this.setCollicoDeliveryDateAndTime(deliveryDaySchedule);
    }
  }

  handlePreSelectedDeliveryDaySchedule(
    preSelectedDate: Date | null,
    deliveryDaysSchedule: IDeliveryDaySchedule[]
  ): IDeliveryDaySchedule | null {
    // Validate input
    if (
      !preSelectedDate ||
      !Array.isArray(deliveryDaysSchedule) ||
      deliveryDaysSchedule.length === 0
    ) {
      return null;
    }

    // Normalize dates to midnight for consistent comparison
    const normalizeDate = (date: Date | string): Date => {
      const normalizedDate = new Date(date);
      normalizedDate.setHours(0, 0, 0, 0);
      return normalizedDate;
    };

    const today = normalizeDate(new Date());
    const requestedDateObj = normalizeDate(preSelectedDate);

    // Check if the requested date is in the past
    if (requestedDateObj < today) {
      return null;
    }

    // Convert delivery date range to normalized dates for efficient comparison
    const normalizedDeliveryDates = deliveryDaysSchedule.map((item) => ({
      ...item,
      normalizedDate: normalizeDate(item.date),
    }));

    // Check if the requested date is exactly in the allowed range
    const exactMatch = normalizedDeliveryDates.find(
      (item) => item.normalizedDate.getTime() === requestedDateObj.getTime()
    );

    if (exactMatch) {
      return exactMatch;
    }

    // Find the next available date in the allowed range
    const nextAvailableDate = normalizedDeliveryDates
      .filter((item) => item.normalizedDate > requestedDateObj)
      .sort(
        (a, b) => a.normalizedDate.getTime() - b.normalizedDate.getTime()
      )[0];

    return nextAvailableDate;
  }
}
