import { Component, HostListener, ViewEncapsulation } from "@angular/core";
import { GeneralService } from "../services/general.service";
import { CollicoService } from "../services/collico.service";
import { UserService } from "../services";
import { CartService } from "src/app/core/services";
import { Subject, merge } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

@Component({
  selector: "sv-notification-bar",
  templateUrl: "./notification-bar.component.html",
  styleUrls: ["./notification-bar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationBarComponent {
  private unsubscribe$ = new Subject<void>();

  displayNotificationBar: boolean = false;
  displayPushedFurtherMsgBeforeInMinutes: number = 30;
  displayTimeLeftMsgBeforeInMinutes: number = 10;
  displayNotificationBarMsg: string = "";
  timeInSeconds: number = 0; // Initial time in seconds
  timerValue: any; // Interval timer reference
  timerDisplay: string;
  cutoffTimeInSeconds: number = 0;
  cutoffTimeInMinutes: number = 0;
  midNightTimeInSeconds: number = 0;
  step: string = "";
  nextDeliveryDate: string;
  refetchShoppingModeInMinutes: number = 120;
  stepMsg: string = "";

  constructor(
    public generalService: GeneralService,
    public userService: UserService,
    private collicoService: CollicoService
  ) {}

  ngOnInit() {
    // Combine all observables to trigger the update when either of them emits
    merge(
      this.collicoService.collicoDeliveryDateAndTimeChanged$,
      this.collicoService.collicoZipChanged$
    )
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe(async (data) => {
        if (data) {
          await this.fetchUserShoppingMode();
        }
      });
  }

  @HostListener("unloaded")
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async fetchUserShoppingMode() {
    const shoppingMode = (await this.userService.getUserShoppingMode()) as any;
    if (shoppingMode) {
      const {
        remaining_time_in_minutes,
        remaining_time_in_seconds,
        midnight_refresh_remaining_time_in_seconds,
        cutoff_message,
        show_notification,
        delivery_message,
        delivery_options,
      } = shoppingMode;
      const { initial, pushed_further, time_ended, time_left } = cutoff_message;
      this.cutoffTimeInSeconds = remaining_time_in_seconds;
      this.cutoffTimeInMinutes = remaining_time_in_minutes;
      this.midNightTimeInSeconds = midnight_refresh_remaining_time_in_seconds;

      if (show_notification) {
        this.displayNotificationBar = true;
        this.generalService.setShowNotificationBar(true);

        if (delivery_options.supports_temperature_controlled_delivery) {
          const displayPushedFurtherMsgInSeconds =
            this.cutoffTimeInSeconds >
            this.displayPushedFurtherMsgBeforeInMinutes * 60
              ? this.cutoffTimeInSeconds -
                this.displayPushedFurtherMsgBeforeInMinutes * 60
              : 0;

          const displayTimeLeftMsgInSeconds =
            this.cutoffTimeInSeconds >
            this.displayTimeLeftMsgBeforeInMinutes * 60
              ? this.cutoffTimeInSeconds -
                this.displayTimeLeftMsgBeforeInMinutes * 60
              : 0;

          // Display the first message (cutoff time till xxx)
          this.step = "initial_cuttoff_time";
          this.stepMsg = initial;

          // Calculate Timer clock
          this.timer();

          // Display the second message (delivery date will be pushed further)
          // If you order before 11:00 on Mon 10.11, delivery time is Tue 11.11, otherwise the delivery date will be pushed further.”
          setTimeout(() => {
            this.step = "cuttoff_time_delvery_date_pushed_further";
            this.stepMsg = pushed_further;
          }, displayPushedFurtherMsgInSeconds * 1000); // Convert seconds to milliseconds

          // Display the second message (x min left msg)
          // If you order before 11:00, delivery time is Tue 11.11. Time left to place the order: 8:32 minutes.
          setTimeout(async () => {
            this.step = "cuttoff_time_x_min_left";
            this.stepMsg = time_left;
          }, displayTimeLeftMsgInSeconds * 1000); // Convert seconds to milliseconds

          // Refetch the shopping mode again after a certain time
          // For next cutOff time like at midnight
          setTimeout(async () => {
            await this.fetchUserShoppingMode();
          }, (this.cutoffTimeInSeconds + this.refetchShoppingModeInMinutes * 60) * 1000); // Convert seconds to milliseconds
        } else {
          this.stepMsg = delivery_message;
          if (delivery_options.allow_editing_deliverydate_at_checkout) {
            this.step = "delivery_available";
          } else {
            this.step = "collico_not_available";
          }
        }
      } else {
        this.displayNotificationBar = false;

        // If customers checkout then no need to display cutoff msgs
        // Display it after certain time
        setTimeout(async () => {
          await this.fetchUserShoppingMode();
        }, this.refetchShoppingModeInMinutes * 60 * 1000); // Convert seconds to milliseconds
      }

      // Display the fourth message (date has changed)
      setTimeout(async () => {
        await this.collicoService.updateDeliveryDateAndTime();
        this.step = "cuttoff_time_date_changed";
        this.stepMsg = time_ended;
      }, this.cutoffTimeInSeconds * 1000); // Convert seconds to milliseconds

      // Midnight refresh like 12:00 AM, so the latest notification text will be fetched
      setTimeout(async () => {
        await this.fetchUserShoppingMode();
      }, this.midNightTimeInSeconds * 1000); // Convert seconds to milliseconds
    }
  }

  closeNotificationBar() {
    this.displayNotificationBar = false;
    this.generalService.setShowNotificationBar(false);
  }

  private timer() {
    clearInterval(this.timerValue);
    this.timeInSeconds = this.cutoffTimeInSeconds; //this.cutoffTimeInSeconds;
    this.timerValue = setInterval(() => {
      if (this.timeInSeconds > 0) {
        this.timeInSeconds--;
        this.timerDisplay = this.formatTime(this.timeInSeconds);
      } else {
        clearInterval(this.timerValue); // Stop the timer when it reaches 0
      }
    }, 1000); // Update the timer every second (1000 milliseconds)
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${this.pad(minutes)}:${this.pad(remainingSeconds)}`;
  }

  private pad(val: number): string {
    return val < 10 ? `0${val}` : val.toString();
  }
}
